
import React from 'react'
export default function IconCaret(props) {
  return (
    <svg
      {
        ...props
      }
      xmlns="http://www.w3.org/2000/svg"
      height={12}
      viewBox="0 0 11.162 6.995"
      >
      <path
        id="Path_1042"
        d="M3141.953,810.522l4.167,4.167,4.167-4.167"
        transform="translate(-3140.539 -809.108)"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeWidth="2"
      />
    </svg>
  )
}