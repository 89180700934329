import { useRef, useMemo, useEffect, useState, Suspense } from 'react';
import { Vector3 } from 'three';
import { Canvas } from '@react-three/fiber'
import {
  CameraControls,
  PerspectiveCamera,
  Environment,
  useProgress,
} from '@react-three/drei';
import { Triangle } from 'react-loader-spinner'
import { debounce } from 'lodash';

import hdr from './assets/canary_wharf_2k_fixed-red.hdr';

import MachineModel from './assets/machine/13464M00004.glb';

import brandLogo from './assets/Koenig_Bauer_Logo.svg';

import CarPart from './CarPart';
import ClickOutside from './ClickOutside';

import eyeView from './assets/view.png';
import eyeHide from './assets/hide.png';
import IconCaret from './icons/IconCaret';
import IconDoubleAngle from './icons/IconDoubleAngle';
// import IconClose from './icons/IconClose';
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';

import './index.css';

function LoadingScreen(props) {
  const [isHidden, setIsHidden] = useState(false);
  useEffect(() => {
    if (props.loaded) {
      setTimeout(() => {
        setIsHidden(true);
      }, 1000);
    }
  }, [props.loaded])
  return <div
    className={
      "w-full h-full absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 filter backdrop-blur transition-opacity delay-500 duration-500 z-40"
      + (props.loaded ? ' opacity-0' : ' opacity-100')
      + (isHidden ? ' invisible' : ' ')
    }
  >
    <Triangle
      height="80"
      width="80"
      color="#002355"
      ariaLabel="triangle-loading"
      wrapperStyle={{}}
      wrapperClass="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
      visible={true}
    />
  </div>;
}

function App() {
  const camRef = useRef();
  const cameraControlsRef = useRef();

  const startDistance = useRef(0);
  const distanceCount = useRef(0);
  const endDistance = useRef(0);

  const [windowSize, setWindowSize] = useState(getWindowSize());

  const isMobileAndTabletControlVertical = useMemo(() => windowSize.innerWidth < 800 && window.matchMedia('(orientation: portrait)').matches, [windowSize.innerWidth]);

  // const searchValueRef = useRef(null);
  const [searchValue, setSearchValue] = useState('');
  const [scene, setScene] = useState(null);
  const [nodeList, setNodeList] = useState(null);
  const [nodeDiplayStatus, setNodeDisplayStatus] = useState(null)
  const [nodeParentTabDisplayStatus, setNodeParentTabDisplayStatus] = useState(null)

  const [showPropTab, setShowPropTab] = useState(true);

  const filteredSceneList = useMemo(() => {
    if (scene && scene.children) {
      const results = scene.children.slice();
      return results.filter((item) => item.name.toLowerCase().includes(searchValue.toLowerCase()));
    } else {
      return [];
    }
  }, [searchValue, scene])
  const { progress } = useProgress();

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
      const vh = window.innerHeight;
      // Then we set the value in the --vh custom property to the root of the document
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    }
    handleWindowResize();
    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  useEffect(() => {
    if (isMobileAndTabletControlVertical) {
      setShowPropTab(false)
    }
  }, [isMobileAndTabletControlVertical])

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  function onCameraUpdate(e) {
    if (cameraControlsRef.current) {
      if (distanceCount.current > 0.9) {
        startDistance.current = e.target.distance;
        distanceCount.current = 0;
      } else {
        distanceCount.current += 0.01;
      }
      handleOnZoom(e.target.distance);
    }
  }

  const handleOnZoom = (distance) => {
    if (distance > startDistance.current) {
      endDistance.current = startDistance.current;
    }
    if (endDistance.current !== 0 && (distance > endDistance.current && distance - endDistance.current > 0.5)) {
      setZoomOut();
    }
  };

  const setZoomOut = debounce(function() {
    endDistance.current = 0;
    cameraControlsRef.current.dolly(-5, true);
    cameraControlsRef.current.setTarget(0, 0, 0, true);
  }, 500);

  function onModelLoaded({ scene, nodes }) {
    setScene(scene);
    setNodeList(nodes)
    if (scene && scene.children && scene.children.length) {
      let displayResults = {};
      let parentTabDisplayResults = {};
      scene.children.forEach((item) => {
        displayResults = {
          ...displayResults,
          [item.name]: true
        }
        parentTabDisplayResults = {
          ...parentTabDisplayResults,
          [item.name]: false
        }
        if (item.children && item.children.length) {
          item.children.forEach((subItem) => {
            displayResults = {
              ...displayResults,
              [subItem.name]: true
            }
          });
        }
      });

      setNodeDisplayStatus(displayResults);
      setNodeParentTabDisplayStatus(parentTabDisplayResults);
    }
  }

  function onSetShowHideParentStatus(name) {
    setNodeParentTabDisplayStatus((prevState) => {
      return {
        ...prevState,
        [name]: !prevState[name]
      }
    })
  }

  function onSetShowHideStatus(name) {
    setNodeDisplayStatus((prevState) => {
      return {
        ...prevState,
        [name]: !prevState[name]
      }
    })
    // if (!nodeList) {
    //   return;
    // }

    // const foundedNode = nodeList[name];

    // let worldPos = new Vector3();
    // if (foundedNode.children && foundedNode.children.length === 0) {
    //   foundedNode.getWorldPosition(worldPos);
    // } else if (foundedNode.children) {
    //   foundedNode.children[0].getWorldPosition(worldPos);
    // }
    // cameraControlsRef.current.setTarget(worldPos.x, worldPos.y, worldPos.z, true);
  }

  function onClickOutside() {
    if (isMobileAndTabletControlVertical) {
      setShowPropTab(false);
    }
  }

  // function onClearSearch() {
  //   if (!searchValueRef.current) {
  //     return;
  //   }
  //   searchValueRef.current.value = '';
  //   setSearchValue('');
  // }

  return (
    <div
      style={{
        height: 'var(--vh)'
      }}
      className="relative"
    >
      <img src={brandLogo} alt="Koenig_Bauer_Logo" className="absolute top-4 left-4 w-auto h-[16px] md:h-[20px] object-contain" />
      <LoadingScreen loaded={progress >= 100 && scene !== null} />
      {
        scene &&
          <ClickOutside func={onClickOutside}>
            <div
              className={
                "absolute w-auto flex flex-col p-4 pr-1 shadow backdrop-blur-sm lg:bg-white top-0 right-0 bottom-4 transition-transform transform z-20 text-sm"
                + (showPropTab ? ' translate-x-0' : ' translate-x-full')
              }
              style={{
                height: 'var(--vh)'
              }}
            >
              <button
                className="absolute bg-white top-4 right-full p-2 shadow z-30"
                onClick={() => setShowPropTab(!showPropTab)}
              >
                <IconDoubleAngle
                  className={
                    "text-[#002355] h-4 transform"
                    + (showPropTab ? ' rotate-0' : ' rotate-180')
                  }
                />
              </button>
              <div
                className={
                  "flex flex-col w-full min-w-[220px] min-height-full h-full gap-2 md:gap-5 transition-transform transform"
                }
              >
                <SimpleBar autoHide={false} style={{ height: '100%', maxHeight: '100%', width:'100%' }}>
                  <ul className="pl-4 flex pr-4 w-full flex-col gap-2 md:gap-5">
                    {
                      filteredSceneList.map((item) =>
                        <li
                          className="flex flex-col gap-2 md:gap-5 pb-4 border-b"
                          key={item.name + 'parent'}
                        >
                          <div
                            className={
                              "flex items-center w-full justify-between sticky top-px"
                              + (nodeParentTabDisplayStatus[item.name] ? ' bg-white py-2 shadow' : '')
                            }
                          >
                            <div className="flex items-center gap-2">
                              <button onClick={() => onSetShowHideStatus(item.name)}>
                                {
                                  nodeDiplayStatus[item.name]
                                    ? <img src={eyeView} alt='eye-icon' className='w-[1rem] h-[1rem] min-w-[1rem] min-h-[1rem] object-contain' />
                                    : <img src={eyeHide} alt='eye-icon' className='w-[1rem] h-[1rem] min-w-[1rem] min-h-[1rem] object-contain opacity-50' />
                                }
                              </button>
                              <p className="whitespace-nowrap">{ item.name }</p>
                            </div>
                            <button className="p-1" onClick={() => onSetShowHideParentStatus(item.name)}>
                              <IconCaret
                                className={
                                  'text-black opacity-70 h-2'
                                }
                                style={{
                                  transform: nodeParentTabDisplayStatus[item.name] ? 'rotate(180deg)' : 'rotate(0)'
                                }}
                              />
                            </button>
                          </div>
                          {
                            item.children && item.children.length
                              ? <ul
                                  className="pl-4 flex flex-col gap-2 md:gap-5"
                                  style={{
                                    display: nodeParentTabDisplayStatus[item.name] ? 'flex' : 'none'
                                  }}
                                >
                                {
                                  item.children.map((subItem) =>
                                    <li
                                      className="flex flex-col gap-2 md:gap-5"
                                      key={subItem.name + 'ch'}
                                    >
                                      <div className="flex items-center gap-2">
                                        <button onClick={() => onSetShowHideStatus(subItem.name)}>
                                          {
                                            nodeDiplayStatus[subItem.name]
                                              ? <img src={eyeView} alt='eye-icon' className='w-[1rem] h-[1rem] min-w-[1rem] min-h-[1rem] object-contain' />
                                              : <img src={eyeHide} alt='eye-icon' className='w-[1rem] h-[1rem] min-w-[1rem] min-h-[1rem] object-contain opacity-50' />
                                          }
                                        </button>
                                        <p className="whitespace-nowrap">{ subItem.name }</p>
                                      </div>
                                    </li>
                                  )
                                }
                              </ul>
                              : <></>
                          }
                        </li>
                      )
                    }
                  </ul>
                </SimpleBar>
              </div>
            </div>
          </ClickOutside>
      }
      <Suspense fallback={<LoadingScreen />}>
        <Canvas
          gl={{
            antialias: true,
            pixelRatio: Math.min(window.devicePixelRatio, 2),
          }}
          id="three-canvas"
          className="w-full h-full"
        >
         <group
            position={
              isMobileAndTabletControlVertical
                ? [0.05,-0.3,0]
                : showPropTab ? [-0.1,-0.3,0] : [0,-0.3,0]
            }
          >
            <CarPart
              modelFile={MachineModel}
              onModelLoaded={onModelLoaded}
              nodeDiplayStatus={nodeDiplayStatus}
            />
          </group>
          <PerspectiveCamera
            ref={camRef}
            fov={isMobileAndTabletControlVertical ? 45 : 30}
            position={[-1.4780651446558473, 0.8397318951435856, 2.171515730546785]}
            makeDefault
          />
          <Environment
              background={false} // Whether to affect scene.background
              files={hdr}
            />
          <CameraControls
            ref={cameraControlsRef}
            minDistance={0.5}
            maxDistance={isMobileAndTabletControlVertical ? 4.2 : 2.7}
            dollyToCursor={true}
            dollySpeed={0.5}
            smoothTime={0.09}
            draggingSmoothTime ={0.15}
            truckSpeed={0}
            onChange={onCameraUpdate}
          />
        </Canvas>
      </Suspense>
    </div>
  );
}

export default App;
