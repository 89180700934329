import { useGLTF } from '@react-three/drei';
import { applyProps } from '@react-three/fiber'
import { useEffect } from 'react';
import { Vector3 } from 'three';

function CarPart(props) {
  const {
    modelFile,
    onModelLoaded,
    nodeDiplayStatus
  } = props;
  const { scene, nodes } = useGLTF(modelFile, '/draco/gltf/')


  useEffect(() => {
    scene.traverse((ch) => {
      if (ch.isMesh) {
        applyProps(ch, { castShadow: true });
        // applyProps(ch, { position: new Vector3(0,0,0) });
      }
    })
    applyProps(scene, { position: new Vector3(0,0,0) });
    onModelLoaded({
      scene,
      nodes
    })
    // eslint-disable-next-line
  }, [scene, nodes])

  useEffect(() => {
    scene.traverse((ch) => {
      if (nodeDiplayStatus && ch.name && ch.name !== 'Scene') {
        if (nodeDiplayStatus[ch.name]) {
          applyProps(ch, { visible: true });
        } else {
          applyProps(ch, { visible: false });
        }
      }
    })
  }, [scene, nodeDiplayStatus])

  return (
    <group position={[0,0,0]} scale={[0.5, 0.5, 0.5]}>
      <primitive object={scene} />
    </group>
  );
}

export default CarPart;
